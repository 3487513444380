import React, { useContext } from "react";
import { Link } from "react-router-dom";
import works from "../icons/briefcase-solid.svg";
import contact from "../icons/envelope-regular.svg";
import about from "../icons/address-card-regular.svg";
import avatar from "../images/wigehts.jpeg";
import { MyContext } from "../index";

export default function Sidebar() {
  let { hightlight, setHightlight } = useContext(MyContext);
  let foo = (e) => {
    if (e === "about") {
      setHightlight({ about: "--navbar__mod--", works: null, contact: null });
    }
    if (e === "works") {
      setHightlight({ about: null, works: "--navbar__mod--", contact: null });
    }
    if (e === "contact") {
      setHightlight({ about: null, works: null, contact: "--navbar__mod--" });
    }
  };
  return (
    <nav className="menu" tabIndex="0">
      <div className="smartphone-menu-trigger"></div>
      <header className="avatar">
        <img src={avatar} alt="404" />
        <h4>Vitalii Ovodovskyu</h4>
      </header>
      <div className="nav__bar">
        <Link
          className={`nav__bar__item  ${hightlight.about}`}
          onClick={() => foo("about")}
          to="/"
        >
          {" "}
          <img className="sidebar__svg" alt="404" src={about}></img>
          <div>About</div>
        </Link>
        <Link
          className={`nav__bar__item  ${hightlight.works}`}
          onClick={() => foo("works")}
          to="/works"
        >
          <img className="sidebar__svg" alt="404" src={works}></img>
          <div>Works</div>
        </Link>
        <Link
          className={`nav__bar__item  ${hightlight.contact}`}
          onClick={() => foo("contact")}
          to="/contact"
        >
          {" "}
          <img className="sidebar__svg" alt="404" src={contact}></img>
          <div>Contact</div>
        </Link>
      </div>
    </nav>
  );
}
