import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import axios from "axios";
import "../styles/about.css";
// import code from "../images/code.svg";
// import { faQuoteRight, faAddressCard } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "./icon";
import { MyContext } from "../index";

const About = () => {
  let { setHightlight } = useContext(MyContext);
  let foo = (e) => {
    if (e === "works") {
      setHightlight({ about: null, works: "--navbar__mod--", contact: null });
    }
    if (e === "contact") {
      setHightlight({ about: null, works: null, contact: "--navbar__mod--" });
    }
  };
  return (
    <div className="container__aboutz">
      <div className="aboutz">
        Hi!
        <br />
        My name is Vitalii, online I'm going as{" "}
        <span style={{ backgroundColor: "black", color: "white" }}>
          hatr1ck
        </span>{" "}
        and I'm doing web development.
        <br />
        This website exist to share my{" "}
        <Link
          onClick={() => foo("works")}
          style={{ backgroundColor: "blue" }}
          to="/works"
        >
          works
        </Link>{" "}
        with the others and{" "}
        <Link
          onClick={() => foo("contact")}
          style={{ backgroundColor: "blue" }}
          to="/contact"
        >
          get in touch
        </Link>{" "}
        with like-minded people.
        <br />
        Tecks that I use:
        <Icons />
      </div>
    </div>
  );
};

export default About;
