import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ParticlesBg from "particles-bg";
import { Switch, BrowserRouter, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "./components/sidebar.js";
import About from "./components/about.js";
const Routes = React.lazy(() => import("./routes"));
export const MyContext = React.createContext();
function App() {
  const location = useLocation().pathname;
  let start = {
    about: null,
    works: null,
    contact: null,
  };
  location === "/"
    ? (start.about = "--navbar__mod--")
    : (start[location.slice(1, location.length)] = "--navbar__mod--");

  let [hightlight, setHightlight] = useState(start);
  return (
    <MyContext.Provider value={{ hightlight, setHightlight }}>
      <div className="App">
        <Sidebar />
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route path="/" exact component={About} />
            <Routes />
          </Switch>
        </Suspense>
      </div>
      <ParticlesBg type="circle" bg={true} />
    </MyContext.Provider>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
